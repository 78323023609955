import * as THREE from "three";
import { FontLoader } from "three/addons/loaders/FontLoader.js";
import { TTFLoader } from "three/examples/jsm/loaders/TTFLoader";

import { TextGeometry } from "three/addons/geometries/TextGeometry.js";
import gsap from "gsap";
import { toScreenXY } from "./toScreenXY";
// div
const titles = [].slice.call(document.getElementsByClassName("animate-title"));

// Canvas
const canvas = document.querySelector("canvas.webgl");

// Scene
const scene = new THREE.Scene();
/**
 * Camera
 */

const height = document.body.clientWidth <= 768 ? 400 : window.innerHeight;

const camera = new THREE.PerspectiveCamera(
  50,
  document.body.clientWidth / height,
  0.1,
  1000
);
camera.position.z = 60;
camera.position.y = 18;
camera.lookAt(0, 0, 0);
// camera.rotation.z = 0.1;
scene.add(camera);
// const controls = new OrbitControls(camera, canvas);
// controls.enableDamping = true;

// Light

const pointLight = new THREE.PointLight("#ffffff", 30, 1000, 0.4);
const ambientLight = new THREE.AmbientLight("#ffffff", 0.4);

pointLight.position.y = 10;
pointLight.position.x = 70;
scene.add(pointLight);
scene.add(ambientLight);

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
  canvas: canvas,
  alpha: true,
});
renderer.setPixelRatio(window.devicePixelRatio);

renderer.setSize(
  document.body.clientWidth,
  document.body.clientWidth <= 768 ? height : window.innerHeight
);
renderer.setClearColor(0x000000, 0);
/**
 * Objects
 */
const geometry = new THREE.SphereGeometry(6, 70, 70);

const manager = new THREE.LoadingManager();
const planetGroup = new THREE.Group();
let selected = 0;
const textureMap = [
  `/static/image/0.jpg`,
  `/static/image/1.jpg`,
  `/static/image/2.jpg`,
  `/static/image/3.jpg`,
  `/static/image/4.png`,
];

const titleGroup = new THREE.Group();
planetGroup.add(titleGroup);
for (let i = 0; i <= 4; i++) {
  const material = new THREE.MeshPhongMaterial({
    map: new THREE.TextureLoader(manager).load(textureMap[i]),
  });

  const mesh = new THREE.Mesh(geometry, material);
  if (i === 0) {
    mesh.scale.set(1.5, 1.5, 1.5);
  } else {
  }
  mesh.name = i;

  mesh.position.z =
    Math.sin(i * ((Math.PI * 2) / 5)) *
    (document.body.clientWidth <= 768 ? 30 : 40);
  mesh.position.x =
    Math.cos(i * ((Math.PI * 2) / 5)) *
    (document.body.clientWidth <= 768 ? 30 : 40);
  mesh.applyMatrix4(planetGroup.matrix);
  planetGroup.add(mesh);

  const text = new THREE.Mesh();
  text.name = titles[i].className + titles[i].dataset.item;

  text.position.z =
    Math.sin(i * ((Math.PI * 2) / 5)) *
    (document.body.clientWidth <= 768 ? 20 : 30);
  text.position.x =
    Math.cos(i * ((Math.PI * 2) / 5)) *
    (document.body.clientWidth <= 768 ? 20 : 30);
  text.position.y = document.body.clientWidth <= 768 ? -3 : -2;

  titleGroup.add(text);
}
planetGroup.rotation.reorder("YXZ");
planetGroup.position.y = -0.5;
planetGroup.rotation.y = (Math.PI / 180) * -90;
// planetGroup.updateMatrixWorld();
// planetGroup.rotation.x = (Math.PI / 180) * 10;
// planetGroup.updateMatrixWorld();

scene.add(planetGroup);
const ringFeometry = new THREE.RingGeometry(39.7, 40, 300);
const material = new THREE.MeshBasicMaterial({
  color: 0xffffff,
  side: THREE.DoubleSide,
  transparent: true,
  opacity: 0.1,
});
const ring = new THREE.Mesh(ringFeometry, material);
ring.rotation.x = Math.PI * 0.5;
ring.position.y = -0.5;

scene.add(ring);
/**
 * Sizes
 */

window.addEventListener("resize", () => {
  const width = document.body.clientWidth;

  const aspect = width / height;
  camera.aspect = aspect;
  camera.updateProjectionMatrix();
  renderer.setSize(width, height);
});

const clock = new THREE.Clock();

const mousePosition = new THREE.Vector2();
const rayCaster = new THREE.Raycaster();
let id;

let intersects = [];
const mouseHandler = (e) => {
  mousePosition.x = (e.offsetX / document.body.clientWidth) * 2 - 1;
  mousePosition.y = -(e.offsetY / canvas.clientHeight) * 2 + 1;

  if (!intersects.length) {
    titles.forEach((el) => el.classList.remove("hover"));
  } else if (intersects[0]) {
    const selectedTile = titles.find((el) => {
      return el.dataset.item == intersects[0].object.name + 1;
    });
    selectedTile.classList.add("hover");
  }
};
renderer.domElement.addEventListener("mousemove", mouseHandler);

const rotate = (Math.PI * 2) / 5;
let prevent = false;
const clickHandler = (e) => {
  if (intersects[0] && !prevent) {
    prevent = true;
    planetGroup.children.forEach((child) =>
      gsap.to(child.scale, {
        duration: 1,
        y: 1,
        x: 1,
        z: 1,
      })
    );

    titles.forEach((el) => (el.className = " animate-title"));
    let increase = (intersects[0].object.name - selected) * rotate;
    switch (increase) {
      case -4 * rotate:
        increase = rotate;
        break;
      case 4 * rotate:
        increase = -1 * rotate;
        break;

      default:
        break;
    }

    gsap.to(planetGroup.rotation, {
      duration: 1,
      y: planetGroup.rotation.y + increase,
    });
    planetGroup.updateWorldMatrix(true, true);
    gsap
      .to(intersects[0].object.scale, {
        duration: 1,
        y: 1.5,
        x: 1.5,
        z: 1.5,
      })
      .then(() => {
        prevent = false;
      });
    selected = intersects[0].object.name;
    const selectedEl = titles.find((el) => el.dataset.item == selected + 1);
    selectedEl.classList.add("active");
  }
};
renderer.domElement.addEventListener("click", clickHandler);
// renderer.domElement.addEventListener("touchstart", clickHandler);
const rect = canvas.getBoundingClientRect();
// animate
function animate() {
  const elapsedTime = clock.getElapsedTime();

  rayCaster.setFromCamera(mousePosition, camera);

  intersects = rayCaster
    .intersectObject(planetGroup)
    .filter((mesh) => mesh.object.name !== selected);
  planetGroup.children
    .filter((item) => {
      return typeof item.name === "number";
    })
    .forEach((planet) => (planet.rotation.y = elapsedTime * 0.3));
  if (titleGroup.children.length === 5) {
    titleGroup.children.forEach((title, i) => {
      const titlePosition = new THREE.Vector3();
      titlePosition.setFromMatrixPosition(title.matrixWorld);
      titlePosition.project(camera);
      const widthHalf = canvas.clientWidth * 0.5;
      const heighthHalf = canvas.clientHeight * 0.5;

      const elm = titles.find((el) => title.name.includes(el.dataset.item));
      titlePosition.x = rect.left + titlePosition.x * widthHalf + widthHalf;
      titlePosition.y = rect.top - titlePosition.y * heighthHalf + heighthHalf;

      // camera.updateProjectionMatrix();

      // const position = toScreenXY(title.position, camera, canvas);

      elm.style.top = titlePosition.y + "px";
      elm.style.left = titlePosition.x + "px";
    });
  }

  renderer.render(scene, camera);
  id = requestAnimationFrame(animate);
}
animate();
if (document.body.clientWidth <= 768) {
  planetGroup.scale.set(0.6, 0.6, 0.6);
  camera.lookAt(0, 0, 14);

  camera.position.y = 25;
  camera.position.z = 50;

  ring.scale.set(0.45, 0.45, 0.45);

  // renderer.domElement.removeEventListener("mousemove", mouseHandler);
  // cancelAnimationFrame(id);
}
